import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MartinSad from '../images/martin-sad.svg';


export default function NotFoundPage() {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <div className="relative">
        <div className="md:overflow-hidden md:pt-12 lg:relative lg:py-18 flex content-center">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl flex flex-col space-y-10">
            <p className="text-3xl lg:text-6xl font-extrabold text-cahoot-blue-med leading-5 tracking-tight sm:text-4xl font-header text-center">
              We're sorry for the 404!
            </p>
            <img src={MartinSad} alt="Martin is sad" className='h-72 object-center'/>
            <p className="text-2xl text-cahoot-blue-dark font-text text-center">
              Martin is sad because he can't find the page you are looking for.<br />
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

